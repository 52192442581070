import { i18n } from "@/plugins/i18n";
import { SOCIAL_SECURITY_TYPE } from "@/helpers/enums";

export default [
  {
    text: i18n.t("social_security_type.SS_4A"),
    value: SOCIAL_SECURITY_TYPE.SS_4A
  },
  {
    text: i18n.t("social_security_type.SS_4B"),
    value: SOCIAL_SECURITY_TYPE.SS_4B
  },
  {
    text: i18n.t("social_security_type.SS_4C"),
    value: SOCIAL_SECURITY_TYPE.SS_4C
  }
];
